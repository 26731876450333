// alert();
$(document).ready(function () {


    $('.btn_menuOpen').click(function () {

        $(this).slideUp();

        $('.sideMenu_Area').toggleClass('in out');
        $('body').toggleClass('sidebar-open');
    });

    $('.logoBox').click(function () {
        $(this).parent('.sideMenu_Area').toggleClass('in out');

        $('.btn_menuOpen').slideDown();
        $('body').toggleClass('sidebar-open');
    });

    $('.sideMenu.not_required li:not(:first-child)').each(function () {
        $(this).click(function () {

            $(this).siblings('li').removeClass('active');
            $(this).addClass('active');

            $(this).parents('.sideMenu_Area').toggleClass('in out');
            $(this).parents('.sideMenu_Area').siblings('.btn_menuOpen').slideDown();
            $('body').toggleClass('sidebar-open');
        });
    });


    if ($(window).width() <= 575) {

        $('.subjects li').each(function () {
            $(this).click(function () {
                $('.subjectDtls').addClass('in');
                $('.subjectDtls_Closer').fadeIn();
            });
        });

        $('.subjectDtls_Closer').click(function () {

            $(this).parents('.subjectDtls').removeClass('in');
            $(this).fadeOut();
        });

    }
});

